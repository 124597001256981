<!--
 * @Author: your name
 * @Date: 2021-02-07 16:17:41
 * @LastEditTime: 2021-02-08 11:44:43
 * @LastEditors: Please set LastEditors
 * @Description: 添加
 * @FilePath: \sd-vue-admin\src\views\project\finance\supplier\receipt\components\AddReceipt.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="receipt"
      title="供应商付款单"
      center
    >
      <div>
        <el-form ref="form" :model="form">
          <el-form-item label="" prop="supp_id">
            <el-select
              v-model="form.supp_id"
              placeholder="请选择供应商"
              @change="handlersupp"
            >
              <el-option
                v-for="list in supplier"
                :key="list.id"
                :label="list.supp_name"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-table
              ref="datatable"
              stripe
              :data="tableData"
              :row-key="getRowKey"
              @selection-change="handlerSelection"
            >
              <el-table-column
                label=""
                align="center"
                type="selection"
                :reserve-selection="true"
              ></el-table-column>
              <el-table-column
                v-for="(item, index) in celund"
                :key="index"
                :label="item.label"
                :align="item.align"
                :prop="item.prop"
              ></el-table-column>
            </el-table>
            <el-row type="flex" class="row-bg" justify="end">
              <el-pagination
                background
                :current-page="sup.pageNo"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="sup.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </el-row>
          </el-form-item>
          <el-form-item label="本页应付款：" prop="money">
            <el-input
              v-model="money"
              placeholder="请先选择运营商"
              disabled
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="实付款：" prop="pay_amount">
            <el-input
              v-model="form.pay_amount"
              placeholder="请输入金额"
              style="width: 220px"
            ></el-input>
          </el-form-item>
          <el-form-item label="付款人：" prop="user_id">
            <el-select v-model="form.user_id" placeholder="请选择付款人">
              <el-option
                v-for="list in userdata"
                :key="list.user_id"
                :label="list.user_name"
                :value="list.user_id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="付款账户：" prop="account_id">
            <el-select v-model="form.account_id" placeholder="请选择付款账户">
              <el-option
                v-for="list in account"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注：" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              placeholder="备注"
              style="width: 220px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlersave">提 交</el-button>
        <el-button @click="receipt = !receipt">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getAction, postAction } from '@/api/Employee'
  export default {
    data() {
      return {
        receipt: false,
        url: {
          supptable: '/cashAdmin/supp/payment-add-list',
          userlist: '/baseAdmin/common/user-list',
          save: '/cashAdmin/supp/add-base',
          supplier: '/purchaseAdmin/base/supp-list',
          account: '/baseAdmin/common/account',
          info: '/cashAdmin/supp/base-detail',
        },
        form: {
          supp_id: '', //供应商ID
          arrears_ids: '', //应付款单ids,用逗号隔开
          pay_amount: '', //实付款
          user_id: '', //付款人id
          account_id: '', //付款账户id
          remark: '', //备注
        },
        account: [],
        supplier: [],
        userdata: [],
        tableData: [],
        celund: [
          {
            label: '应付款单',
            align: 'center',
            prop: 'bill_code',
          },
          {
            label: '关联采购单',
            align: 'center',
            prop: 'order_ori_code',
          },
          {
            label: '下单时间',
            align: 'center',
            prop: 'create_at',
          },
          {
            label: '剩余应付款金额',
            align: 'center',
            prop: 'arrears_amount',
          },
        ],
        sup: {
          id: '',
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        summe: [],
      }
    },
    computed: {
      money() {
        var sum = 0
        this.summe.forEach((list) => {
          sum += Number(list.arrears_amount)
        })
        return sum
      },
    },
    mounted() {
      this.handlersupplier()
      this.hanlderuserlist()
      this.handleraccount()
    },
    methods: {
      handlerData(row) {
        postAction(this.url.info, { id: row.id })
          .then((res) => {
            console.log(res, '')
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlersave() {
        postAction(this.url.save, this.form)
          .then((res) => {
            console.log(res, '')
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.$emit('getlist')
            this.receipt = false
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerSelection(val) {
        console.log(val, '')
        this.summe = val
        var ids = new Array()

        val.forEach((list) => {
          ids.push(list.id)
        })
        this.form.arrears_ids = ids.join(',')

        console.log(ids)
      },
      getRowKey(row) {
        return row.id
      },
      handlersupp(val) {
        console.log('供应商', val)
        this.sup.id = val
        this.handlersup()
      },
      handlersup() {
        postAction(this.url.supptable, this.sup)
          .then((res) => {
            console.log(res, '供应商付款单')
            this.tableData = res.data.rows
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.sup.pageSize = val
        this.handlersup()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.sup.pageNo = val
        this.handlersup()
      },
      // 收款账户
      handleraccount() {
        postAction(this.url.account, {})
          .then((res) => {
            console.log(res, '收款账户')
            this.account = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //供应商
      handlersupplier() {
        postAction(this.url.supplier, {})
          .then((res) => {
            console.log(res, '供应商')
            this.supplier = res.data.rows
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //收款人
      hanlderuserlist() {
        postAction(this.url.userlist, {})
          .then((res) => {
            console.log('收款人', res)
            this.userdata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>

<style></style>
